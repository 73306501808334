@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie\:mb-0 {
    margin-bottom: 0 !important;
  }
  .ie\:top-5 {
    top: 1.25rem !important;
  }
  .ie\:pt-0 {
    padding-top: 0 !important;
  }
}
